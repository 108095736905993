@font-face {
  font-family: "Sunday";
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/Sunday-Normal.otf") format("opentype");
}

@font-face {
  font-family: "Sunday-Regular";
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/Sunday-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Sunday";
  font-style: normal;
  font-weight: bold;
  src: url("./fonts/Sunday-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Sunday";
  font-style: normal;
  font-weight: light;
  src: url("./fonts/Sunday-Light.otf") format("opentype");
}
